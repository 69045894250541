<template>
  <v-app>
    <vue-particles
      color="#240b36"
      :particleOpacity="0.7"
      :particlesNumber="50"
      shapeType="star"
      :particleSize="4"
      linesColor="#c31432"
      :linesWidth="2"
      :lineLinked="true"
      :lineOpacity="0.4"
      :linesDistance="150"
      :moveSpeed="3"
      :hoverEffect="true"
      hoverMode="grab"
      :clickEffect="true"
      clickMode="push"
    >
    </vue-particles>
    <v-container class="container">
      <v-card width="95%" class="grid" :elevation="11">
        <v-form v-model="valid">
          <v-row>
            <v-col cols="12" md="4">
              <v-card-text class="text">Full Name</v-card-text>
              <v-text-field
                dark
                v-model="user.name"
                :rules="nameRules"
                :counter="40"
                label="Full Name"
                required
                class="text"
                dense
                solo
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <v-card-text class="text">Email Address</v-card-text>
              <v-text-field
                dark
                v-model="user.email"
                :rules="emailRules"
                label="E-mail"
                required
                dense
                solo
                class="text"
              ></v-text-field>
            </v-col>

            <v-col :cols="12" md="4">
              <v-card-text class="text"
                >Identification / Passport Number</v-card-text
              >
              <v-text-field
                dark
                v-model="user.primaryIdentificationNum"
                label="IC / Passport Number"
                required
                dense
                class="text"
                solo
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col :cols="12" md="4">
              <v-card-text class="text">Address</v-card-text>
              <v-text-field
                dark
                v-model="user.address"
                label="Address"
                required
                dense
                class="text"
                solo
              ></v-text-field>
            </v-col>

            <v-col :cols="12" md="4">
              <v-card-text class="text">Mobile Number</v-card-text>
              <vue-phone-number-input
                v-model="user.mobileNumber"
                required
                dense
                solo
                dark
                default-country-code="MY"
                @update="results = $event"
                :success="results?.isValid"
              />
            </v-col>

            <v-col :cols="12" md="4">
              <v-card-text class="text">Deactivate User?</v-card-text>
              <v-simple-checkbox
              style="
                          background-image: linear-gradient(to right, #ad5389, #3c1053);
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        "
                class="text-center justify-center"
                v-model="user.isActive"
              ></v-simple-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col :cols="12" md="4">
              <v-card-text class="text"
                >Upload your utility bill (eg : TNB)</v-card-text
              >
              <v-file-input
                :rules="[rules.required]"
                style="
                          background-image: linear-gradient(to right, #ad5389, #3c1053);
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        "
                prepend-icon="mdi-image-outline"
                accept="image/*"
                @change="utilityImage" />
              <div v-if="user.utilityImageUrl">
                <v-card class="grid" width="300px" height="300px">
                  <v-row
                    justify="center"
                    align="center"
                    style="height: 61px !important"
                  >
                    <v-btn
                      class="rounded-btn"
                      dark
                      @click="removeUrl('utility')"
                    >
                      x
                    </v-btn>
                  </v-row>
                  <a :href="user.utilityImageUrl" download="utility-image.jpg">
                    <v-img
                      :src="user.utilityImageUrl"
                      height="250px"
                      width="250px"
                      contain
                    >
                    </v-img></a
                ></v-card></div
            ></v-col>
            <v-col :cols="12" md="4">
              <v-card-text class="text">Uploaded Primary Document</v-card-text>
              <v-file-input
              style="
                          background-image: linear-gradient(to right, #ad5389, #3c1053);
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        "
                prepend-icon="mdi-image-outline"
                accept="image/*"
                @change="primaryImage" />

              <div v-if="user.primaryIdentificationUrl">
                <v-card class="grid" width="300px" height="300px" dark>
                  <v-row
                    justify="center"
                    align="center"
                    style="height: 61px !important"
                  >
                    <v-btn
                      class="rounded-btn"
                      dark
                      @click="removeUrl('primary')"
                    >
                      x
                    </v-btn>
                  </v-row>
                  <a
                    :href="user.primaryIdentificationUrl"
                    download="primary-image.jpg"
                  >
                    <v-img
                      :src="user.primaryIdentificationUrl"
                      height="250px"
                      width="250px"
                      contain
                    >
                    </v-img></a
                ></v-card></div
            ></v-col>
            <v-col :cols="12" md="4">
              <v-card-text class="text"
                >Uploaded Secondary Document</v-card-text
              >
              <v-file-input
              style="
                          background-image: linear-gradient(to right, #ad5389, #3c1053);
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        "
                prepend-icon="mdi-image-outline"
                accept="image/*"
                @change="secondaryImage" />
              <div v-if="user.secondaryIdentificationUrl">
                <v-card class="grid" width="300px" height="300px">
                  <v-row
                    justify="center"
                    align="center"
                    style="height: 61px !important"
                  >
                    <v-btn
                      class="rounded-btn"
                      dark
                      @click="removeUrl('secondary')"
                    >
                      x
                    </v-btn>
                  </v-row>
                  <a
                    :href="user.secondaryIdentificationUrl"
                    download="secondary-image.jpg"
                  >
                    <v-img
                      :src="user.secondaryIdentificationUrl"
                      height="250px"
                      width="250px"
                      contain
                    >
                    </v-img> </a
                ></v-card></div
            ></v-col>
          </v-row>
          <v-row
            class="justify-center align-center"
            no-gutters
            style="margin-top: 2% !important"
          >
            <v-btn class="btnWa" @click="changePassword = true">
              <v-icon style="
                          background-image: linear-gradient(to right, #ad5389, #3c1053);
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        "> mdi-lock-reset mdi-18px </v-icon>
              Change User Password?
            </v-btn>
          </v-row>
          <v-row v-if="changePassword">
            <v-col :cols="12" md="4">
              <v-card-text class="text">Current Password</v-card-text>
              <v-text-field
                dark
                v-model="password.currentPassword"
                required
                dense
                class="text"
                solo
                :append-icon="value ? 'visibility' : 'visibility_off'"
                @click:append="() => (value = !value)"
                :type="value ? 'password' : 'text'"
              ></v-text-field>
            </v-col>
            <v-col :cols="12" md="4">
              <v-card-text class="text">New Password</v-card-text>
              <v-text-field
                dark
                v-model="password.newPassword"
                required
                dense
                class="text"
                solo
                :append-icon="value2 ? 'visibility' : 'visibility_off'"
                @click:append="() => (value2 = !value2)"
                :type="value2 ? 'password' : 'text'"
              ></v-text-field>
            </v-col>
            <v-col :cols="12" md="4">
              <v-card-text class="text">Confirm New Password</v-card-text>
              <v-text-field
                dark
                v-model="password.confirmNewPassword"
                required
                dense
                class="text"
                solo
                :append-icon="value2 ? 'visibility' : 'visibility_off'"
                @click:append="() => (value2 = !value2)"
                :type="value2 ? 'password' : 'text'"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row class="justify-center align-center" no-gutters>
            <v-btn class="btnWa" @click="showEmailForm = true">
              <v-icon style="
                          background-image: linear-gradient(to right, #ad5389, #3c1053);
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        ">mdi-gmail mdi-18px</v-icon>
              Send Email
            </v-btn>

            <v-dialog v-model="showEmailForm">
              <v-card>
                <v-card-title>
                  Send Email
                  <v-spacer></v-spacer>
                  <v-btn icon @click="showEmailForm = false">
                    <v-icon>mdi-close mdi-18px</v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text>
                  <v-text-field v-model="email.to" label="To"></v-text-field>
                  <v-select
                    v-model="email.template"
                    :items="emailTemplates"
                    label="Email Template"
                    @input="setEmailBody"
                  ></v-select>
                  <v-text-field
                    v-model="email.subject"
                    label="Email Subject"
                  ></v-text-field>
                  <v-textarea
                    v-model="email.body"
                    label="Email Body"
                  ></v-textarea>
                </v-card-text>
                <v-card-actions>
                  <v-btn class="btnWa" @click="sendEmail"
                    ><v-icon
                      style="
                          background-image: linear-gradient(to right, #ad5389, #3c1053);
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        "
                    >mdi-gmail</v-icon
                    >Send</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-btn class="btnWa" @click="update()">
              <v-icon
                style="
                          background-image: linear-gradient(to right, #ad5389, #3c1053);
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        "
              >
                mdi-account-plus-outline mdi-18px
              </v-icon>
              Update
            </v-btn>
          </v-row>
        </v-form>
      </v-card>
    </v-container></v-app
  >
</template>
<script>
import DataService from "../services/dataService.js";
const dataService = new DataService();
import emailTemplates from "../config/email";
export default {
  data() {
    return {
      user: {},
      isValid: false,
      changePassword: false,
      password: {},
      email: {
        to: "",
        subject: "",
        template: "",
        body: "",
      },
      showEmailForm: false,
      // user: {
      //   mobileNumber: "",
      //   email: "",
      //   password: "",
      //   name: "",
      //   address: "",
      //   primaryIdentificationNum: "",
      // },
      results: [],
      nameRules: [
        (value) => {
          if (value) return true;

          return "Name is requred.";
        },
        (value) => {
          if (value?.length <= 40) return true;

          return "Name must be less than 40 characters.";
        },
      ],
      value: String,
      value2: String,
      valid: true,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => (v && v.length >= 8) || "Min 8 characters",
      },
      emailRules: [
        (v) => !!v || "Required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      errorMessage: false,
    };
  },
  mounted: function () {
    this.getUser();
  },

  computed: {
    canSubmit() {
      return this.email && this.password;
    },
    emailTemplates() {
      return Object.keys(emailTemplates).map((key) => emailTemplates[key].name);
    },
  },

  methods: {
    setEmailBody() {
      const template =
        emailTemplates[this.email.template.toLowerCase().replace(/ /g, "")];
      if (template) {
        this.email.subject = template.subject;
        this.email.body = template.body;
        this.email.body = template.body.replace(/{{name}}/g, this.user.name);
      }
    },
    async sendEmail() {
      // send email logic
      await dataService.sendEmail(this.email).then((res) => console.log(res));
      this.showEmailForm = false;
    },
    async getUser() {
      if (this.$store.state.user.roles[0] == "admin") {
        this.$setLoader();
        await dataService
          .getUser({ _id: this.$route.params.cardId })
          .then((res) => {
            this.user = res.data.user[0];
            this.email.to = this.user.email;
            this.password.currentPassword = this.user.password;
          });
        this.$disableLoader();
      } else {
        alert("USER NOT AUTHORIZED TO VIEW THIS PAGE");
        this.$router.push({ path: `/car-rental-seremban` });
      }
    },
    async update() {
      delete this.user.password;
      try {
        this.$setLoader();
        this.user.countryCode = this.results.countryCode;
        this.user.mobileNumber = this.results.formattedNumber;
        this.user.userId = this.user._id;
        await dataService
          .update({ user: this.user, password: this.password, admin: true })
          .then((res) => {
            console.log(res);
            window.location.reload();
          });
        this.$disableLoader();
      } catch (error) {
        // alert("Error 1000 - Page does not exists!")
      }
      //   this.$router.push({ path: `/register` });
    },

    removeUrl(name) {
      if (name == "utility") {
        this.user.utilityImageUrl = null;
      }
      if (name == "primary") {
        this.user.primaryIdentificationUrl = null;
      }
      if (name == "secondary") {
        this.user.secondaryIdentificationUrl = null;
      }
    },

    utilityImage(file) {
      if (file) {
        this.$setLoader();
        file.resource_type = "image";
        file.url = process.env.VUE_APP_CLOUDINARY_URL_IMAGE;
        this.uploadFileToCloudinary(file).then((fileResponse) => {
          // Build the Cloudinary URL with transformation parameters
          this.user.utilityImageUrl = `https://res.cloudinary.com/${process.env.VUE_APP_CLOUDINARY_CLOUD_NAME}/image/upload/c_fill,f_auto,q_auto/${fileResponse.public_id}.${fileResponse.format}`;

          this.$disableLoader();
        });
      }
    },

    primaryImage(file) {
      if (file) {
        this.$setLoader();
        file.resource_type = "image";
        file.url = process.env.VUE_APP_CLOUDINARY_URL_IMAGE;
        this.uploadFileToCloudinary(file).then((fileResponse) => {
          this.user.primaryIdentificationUrl = `https://res.cloudinary.com/${process.env.VUE_APP_CLOUDINARY_CLOUD_NAME}/image/upload/c_fill,f_auto,q_auto/${fileResponse.public_id}.${fileResponse.format}`;
          this.user.isActive = false;
          //   this.product.productImageUrl = this.preview;
          this.$disableLoader();
        });
      }
    },

    secondaryImage(file) {
      if (file) {
        this.$setLoader();
        file.resource_type = "image";
        file.url = process.env.VUE_APP_CLOUDINARY_URL_IMAGE;
        this.uploadFileToCloudinary(file).then((fileResponse) => {
          this.user.secondaryIdentificationUrl = `https://res.cloudinary.com/${process.env.VUE_APP_CLOUDINARY_CLOUD_NAME}/image/upload/c_fill,f_auto,q_auto/${fileResponse.public_id}.${fileResponse.format}`;
          this.user.isActive = false;
          //   this.product.productImageUrl = this.preview;
          this.$disableLoader();
        });
      }
    },

    uploadFileToCloudinary(file) {
      return new Promise(function (resolve, reject) {
        let formData = new FormData();
        formData.append(
          "upload_preset",
          process.env.VUE_APP_CLOUDINARY_PRESET1
        );
        formData.append("resource_type", file.resource_type);
        formData.append("file", file);
        let request = new XMLHttpRequest();
        request.open(
          "POST",
          `https://api.cloudinary.com/v1_1/${process.env.VUE_APP_CLOUDINARY_CLOUD_NAME}/upload`,
          true
        );
        request.setRequestHeader("X-Requested-With", "XMLHttpRequest");
        request.onreadystatechange = () => {
          if (request.readyState === 4 && request.status === 200) {
            let response = JSON.parse(request.responseText);
            resolve(response);
          }
          if (request.status !== 200) {
            let response = JSON.parse(request.responseText);
            let error = response.error.message;
            this.errorText = "error uploading files " + error;
            this.isError = true;
            reject(error);
          }
        };
        request.onerror = (err) => {
          alert("error: " + err);
          reject(err);
        };
        request.send(formData);
      });
    },
  },
};
</script>

<style scoped lang="css">
.container {
  /* margin-top: 5%; */
  max-width: none !important;
}

#particles-js {
  background-color: #f9ece0 !important;
  position: absolute !important;
  width: 100% !important;
  height: 100% !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 0 !important;
}
</style>
